<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div>

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> تعداد کل : </span>
              <span class="mx-1">{{ totalCount }}</span>
            </b-col>

            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="count"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector mx-50 w-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchCommand"
                    class="d-inline-block mr-1"
                    placeholder="جستجوی شماره موبایل ..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="users"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="count"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <!--    <b-modal-->
    <!--        id="modal-delete"-->
    <!--        centered-->
    <!--        ok-title="حذف"-->
    <!--        cancelTitle="انصراف"-->
    <!--        @ok="deleteCoupon(deleteItem)"-->
    <!--    >-->
    <!--      <span>حذف شود؟</span>-->
    <!--    </b-modal>-->
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
import {NumberGetAllRequest} from "@/libs/Api/Number";
import {UsersAddTransgressiveUserRequest, UsersGetTransgressiveUsersRequest} from "@/libs/Api/Users";

export default {
  title:"لیست متخلفان - پنل ادمین مکس ",
  data(){
    return{
      coupons:null,
      users:[],
      showOverlay:false,
      totalCount:null,
      addNewCategory:false,
      currentPage:1,
      deleteItem:null,
      perPage:10,
      pageNumber:1,
      count:10,
      searchCommand:'',
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'fullName',label:'اطلاعات کاربر'},
        { key: 'userName',label:'نام کاربری'},
        { key: 'mobile',label:'موبایل'},
        // { key: 'reason',label:'تخلف'},
        { key: 'tryCopingVideoCount',label:'تعداد دفعات تلاش برای کپی'},
        { key: 'useAppMoreThan2DeviceCount',label:'تعداد دفعات ورود با بیش از دو دستگاه'},
      ],
      defaultCreateData:{
        isDeleted:false,
        productCategoryId:0,
        parentId:0,
        name:''
      },
    }
  },
  async created(){
    await this.getTransgressiveUsers(this.pageNumber,this.count,this.searchCommand)
  },
  methods:{
    async getTransgressiveUsers(pageNumber,count,searchCommand){
      let  _this = this;

      _this.showOverlay = true;

      let data = {
        page:pageNumber,
        size:count,
        search:searchCommand
      }

      let usersGetTransgressiveUsersRequest = new UsersGetTransgressiveUsersRequest(_this);
      usersGetTransgressiveUsersRequest.setParams(data);
      await usersGetTransgressiveUsersRequest.fetch(function (content){
        _this.users = content.data.data;
        _this.totalCount = content.data.totalCount;
        _this.showOverlay = false;
      },function (error){
        _this.showOverlay = false;
        console.log(error)
      })
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    currentPage:function (nv,ov) {
      this.getTransgressiveUsers(nv,this.count,this.searchCommand);
    },
    count:function (nv,ov) {
      this.getTransgressiveUsers(this.currentPage,nv,this.searchCommand);
    },
    searchCommand:function (nv,ov) {
      this.getTransgressiveUsers(this.currentPage,this.count,nv);
    },
  },
  components:{
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style scoped>

</style>